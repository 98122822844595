import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ActivitiesListing from '../components/ActivitiesListing';
import ModularBlocks from '../components/ModularBlocks';

const ActivitiesArchive = () => {
  const {
    datoCmsActivitiesArchive: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      listingOverline,
      listingHeading,
      listingText,
      modularBlocks,
    },
    allDatoCmsActivity: { nodes },
  } = useStaticQuery(graphql`
    query ActivitiesArchiveQuery {
      datoCmsActivitiesArchive {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerImage {
          ...SubPageBannerImageFragment
        }
        bannerText
        listingOverline
        listingHeading
        listingText
        modularBlocks {
          ...ContainedImageModularBlockFragment
          ...ContentModularBlockFragment
          ...ImageGalleryModularBlockFragment
          ...ImagesContentModularBlockFragment
          ...PackagesModularBlockFragment
          ...TestimonialsModularBlockFragment
        }
      }
      allDatoCmsActivity(sort: { fields: position }) {
        nodes {
          ...ActivityListingItemFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} image={bannerImage} text={bannerText} />
        <ActivitiesListing
          overline={listingOverline}
          heading={listingHeading}
          text={listingText}
          items={nodes}
          isActivitiesArchive={true}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default ActivitiesArchive;
